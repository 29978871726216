// "use-client"
import BodyRow from "@/components/core/table/body";
import Head from "@/components/core/table/head";
import { INLINES_ASSET_HYPERLINK, INLINES_EMBEDDED_ENTRY, INLINES_ENTRY_HYPERLINK, INLINES_HYPERLINK } from "@/components/utility/rich-text-options/rich-text-renderers";
import { isTouch } from "@/hooks/useWindowSize";
import { DesignSystemComponent, TableT } from "@/types/design-system-types";
import { cn } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { ReactNode, useEffect, useRef, useState } from "react";
import useLocalisedText from "@/hooks/useLocalisedText";
import { TextKey } from "@/config/i18n";

// Map of locale to instrument endpoint translations
export const localeMap = new Map([
  ["de-AT", {
    urlPath: "instrumente",
    received: "erhalten",
    charged: "zahlen",
    annual: "jährlich",
    overnight: "täglich",
    currencySymbol: "€"
  }],
  ["de-DE", {
    urlPath: "instrumente",
    received: "erhalten",
    charged: "zahlen",
    annual: "jährlich",
    overnight: "täglich",
    currencySymbol: "€"
  }],
  ["es-ES", {
    urlPath: "instrumentos",
    received: "recibido",
    charged: "cobrado",
    annual: "anual",
    overnight: "diario",
    currencySymbol: "€" //es-es (Sonia) requested "daily" instead of "overnight"
  }],
  ["fr-FR", {
    urlPath: "instruments",
    received: "reçu",
    charged: "facturé",
    annual: "annuel", // ai gen
    overnight: "quotidien",
    currencySymbol: "€" // ai gen
  }],
  ["it-IT", {
    urlPath: "strumenti",
    received: "ricevuto",
    charged: "addebitato",
    annual: "annuale", // ai gen
    overnight: "giornaliero",
    currencySymbol: "€" // ai gen
  }],
  ["pl-PL", {
    urlPath: "instrumenty",
    received: "otrzymano",
    charged: "naliczono",
    annual: "roczny",
    overnight: "dzienny",
    currencySymbol: "PLN"
  }],
  ["sv-SE", {
    urlPath: "instrument",
    received: "mottaget",
    charged: "debiterad",
    annual: "årlig",
    overnight: "daglig",
    currencySymbol: "KR"
  }],
  ["nb-NO", {
    urlPath: "instrument",
    received: "mottatt",
    charged: "belastet",
    annual: "årlig",
    overnight: "daglig",
    currencySymbol: "KR"
  }],

  // Claude AI GEN
  ["zh", {
    urlPath: "instruments",
    received: "收到",
    charged: "支付",
    annual: "年度", 
    overnight: "日常",
    currencySymbol: "¥"
  }]
]);

const Table = ({
  data, // All incoming Contentful data
  debug, // Debug framer

  structure = data?.fields?.structure,

  className,
  ...props
}: DesignSystemComponent<TableT>) => {
  try {
    if (!structure) return null;

    return (
      <div {...props} className={cn("rich-text relative px-0", className)}>
        {debug}
        {documentToReactComponents(structure as any, {
          renderNode: {
            [BLOCKS.TABLE]: (_, children: ReactNode): ReactNode => (
              <TableRenderer {...props} table={children} debug={debug} />
            ),

            // For rendering nDID (e.g. likely for pricing disclaimer)
            [INLINES.EMBEDDED_ENTRY]: (node: any) => INLINES_EMBEDDED_ENTRY(node),
            [INLINES.HYPERLINK]: (node: any, children: ReactNode) => INLINES_HYPERLINK(node, children),
            [INLINES.ENTRY_HYPERLINK]: (node: any, children: ReactNode) => INLINES_ENTRY_HYPERLINK(node, children),
            [INLINES.ASSET_HYPERLINK]: (node: any, children: ReactNode) => INLINES_ASSET_HYPERLINK(node, children),
            [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => {
              // ? Stop hydration warnings by preventing <p> tags wrapping embedded entries
              const hasEmbeddedEntry = node.content.some(
                (contentNode: Node) =>
                  contentNode.nodeType as unknown === "embedded-entry-inline"
              );
              return hasEmbeddedEntry ? <>{children}</> : <p>{children}</p>;
            },
          },
        })}
      </div>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

const TableRenderer = ({ table }: any) => {
    const swipeText = useLocalisedText(TextKey.Swipe);

    // Generate a random ID for this table instance. Used for generating unique IDs to pair with instrument API codes
    const [uniqueTableId, setUniqueTableId] = useState<string | null>(null);

    useEffect(() => {
      setUniqueTableId(`table-${Math.random().toString(36).substring(7)}`);
    }, [])

    const thisTable = useRef<HTMLTableElement>(null);
    const swipeIcon = useRef<HTMLDivElement>(null);

    const [unhideRows, setUnhideRows] = useState(0);

    const tableElements = table as any;
    const heads = tableElements[0].props.children;
    const bodyRows = tableElements.slice(1);

    // Checks if the table is dynamic (i.e. has {{{ }}} syntax (excluding NO-STICK keyword))
    const isDynamicTable = (): boolean => {
      return heads.some((head: any) => {
        const children = head?.props?.children?.[0]?.props?.children?.[0];
        return typeof children === "string" && children.match(/\{\{\{(?!\s*NO-STICK\s*\}\}\})\s*(.*?)\s*\}\}\}/g);
      });
    }

    // By default, sticky column should be enabled BUT
    // If any column has the NO-STICK keyword, the first column should not be sticky
    const enableStickyFirstColumn = (): boolean => {
      return !heads.some((head: any) => {
        const children = head?.props?.children?.[0]?.props?.children?.[0]
        return typeof children === "string" && children.match(/\{\{\{\s*NO-STICK\s*\}\}\}/g);
      })
    }

    let showNumber = 2;

    const showMore = () => setUnhideRows((prev) => (prev += showNumber));

    let showMoreText = "Show more";

    // Checks if any rows have the SHOW-MORE keyword
    const showMoreIndex = bodyRows.findIndex((tr: any) =>
      tr.props.children.some((td: any) => {
        // if(typeof td?.props?.children?.[0]?.props?.children?.[0] !== "string") {
        //   return null;
        // }

        const children = td?.props?.children?.[0]?.props?.children?.[0];

        if (typeof children !== "string") return null;

        const match = children.match(/\{\{\{\s*SHOW-MORE(?:-\d+)?\s*\}\}\}/);

        if (match && match[0]) {
          // If there is a string after SHOW-MORE, use that as the text
          if(children?.replace(/\{\{\{\s*SHOW-MORE(?:-\d+)?\s*\}\}\}/, "").trim() !== "") {
            showMoreText = children.replace(/\{\{\{\s*SHOW-MORE(?:-\d+)?\s*\}\}\}/, "").trim();
          }

          showNumber = match[0].split("-")[2] ? parseInt(match[0].split("-")[2]) : 2;
          return match[0];
        }

        return null;
      })
    );

    const initialVisibleRows = showMoreIndex >= 0 ? bodyRows.slice(0, showMoreIndex) : bodyRows;
    const hiddenRows = showMoreIndex >= 0 ? bodyRows.slice(showMoreIndex + 1) : [];

    // All the hidden rows that will be shown when the user clicks "Show more"
    const unhiddenRows =showMoreIndex >= 0 ? hiddenRows.slice(0, unhideRows) : [];

    // Adds grab cursor and swipe icon if the table is wider than its parent
    const addSwipeIcon = () => {    // const swipeIcon = thisTable?.current?.querySelector("#swipe-icon");

      const setStickyFirstColumn = enableStickyFirstColumn();

      if (thisTable.current) {
        const t = thisTable.current;
        const tableParent = t.parentElement;

        if (!tableParent) return;

        if(!setStickyFirstColumn) {
          thisTable.current?.classList.remove("sticky-first-column");
        } else {
          thisTable.current?.classList.add("sticky-first-column");
        }

        if (tableParent.offsetWidth < t.clientWidth && isTouch()) {
          swipeIcon?.current?.classList.remove("hidden");
          setStickyFirstColumn && t.classList.add("sticky-first-column");
        } else {
          swipeIcon?.current?.classList.add("hidden");
          setStickyFirstColumn && t.classList.remove("sticky-first-column");
        }

        if (tableParent.offsetWidth < t.clientWidth && !isTouch()) {
          swipeIcon?.current?.classList.remove("hidden");
          setStickyFirstColumn && t.classList.add("sticky-first-column");
          const slider = tableParent;

          slider.style.cursor = "grab";
          let startX: number;
          let scrollLeft: number;
          let isDown = false;

          slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.style.cursor = "grabbing";
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          });
          slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
          });
          slider.addEventListener("mouseup", () => {
            slider.style.cursor = "grab";
            isDown = false;
            slider.classList.remove("active");
          });
          slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = x - startX;
            slider.scrollLeft = scrollLeft - walk;
          });
        } else if (t.parentElement.offsetWidth === t.clientWidth) {
          t.parentElement.style.cursor = "unset";
          t.style.cursor = "unset";
        }
      }
    };

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let _ of entries) addSwipeIcon();
      });

      if (thisTable.current) resizeObserver.observe(thisTable.current);

      return () => {
        if (thisTable.current) resizeObserver.unobserve(thisTable.current);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Toggle swipe icon based off {{{ NO-STICK }}} syntax
    useEffect(() => {
      addSwipeIcon()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table]);


    if (!table) return null;

    return (
      <>
        <div ref={swipeIcon} className="swipe-icon">{swipeText}</div>
        <div className={`cmc-table ${isDynamicTable() ? "dynamic-table" : ''}`}>
          <table ref={thisTable}>
            <thead>
              <tr>
                {heads.map((head: any, index: number) => (
                  <Head
                    key={index}
                    head={head}
                    thisTable={thisTable}

                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Render all the initially visible rows plus any unhidden rows (if any) */}
              {[...initialVisibleRows, ...unhiddenRows].map((bodyRow: any, index: number) => {
                return <BodyRow
                  uniqueTableId={uniqueTableId}
                  key={index}
                  heads={heads}
                  data={bodyRow}

                />;
              })}
              {showMoreIndex >= 0 && unhiddenRows.length !== hiddenRows.length && (
                  <tr id="show-more" className="show-more">
                    <td className="show-more" colSpan={heads.length}>
                      <div className="">
                        <p className="btn btn-primary" onClick={showMore}>{showMoreText}</p>
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </>
    );

};

export default Table;
