// TODO: Clean up exemption for ZH using alcatraz logic

import useInView from "@/hooks/useInView";
import WSFetcher, { RegionAndType } from "@/services/fetchers/ws-fetcher";
import {
  cn,
  findParentComponentIdByType,
  getRouteDetails,
  isAlcatraz,
  numberFormatter,
} from "@/utils/helpers";
import { TableCell } from "@contentful/rich-text-types";
import { LoaderCircle, X } from "lucide-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import uPlot from "uplot";
import { triggerUpdateTabHeight } from "../molecules/tabs";
import { ComponentError } from "@/utils/system/component-error";
import { localeMap } from "../molecules/table";
import { Locales } from "@/config/supported-countries";

type CellTypes = "NAME" | "MIN-SPREAD" | "MIN-SPREAD-DISCOUNT" | "MARGIN-RATE" | "PRICE" | "DAY" | "WEEK" | "MONTH" | 
"YEAR" |  "HOLDING-COST-BUY" | "HOLDING-COST-SELL" |  "HOLDING-COST-BUY-AO" | "HOLDING-COST-SELL-AO" |  "HOLDING-COST-BUY-OVERNIGHT" | "HOLDING-COST-SELL-OVERNIGHT" |
"COMMISSION" | "TREND";

// Generates the trend chart
const Trend = ({ trend }: any) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(150); // Default width, adjust as needed

  const debounce = useCallback((func: any, wait: any) => {
    let timeout: any;
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }, []);

  const updateWidth = () =>
    chartRef.current && setWidth(chartRef.current.offsetWidth);

  // Memoize formatted data
  const formattedData = useMemo(() => {
    if (!trend || trend.length === 0) {
      return [[], []];
    }

    const dates = trend.map((item: any) =>
      Math.floor(new Date(item.t).getTime() / 1000)
    );
    const defaultOpenVal = trend[0].o;
    const opens = trend.map((item: any) => {
      const change = ((item.o - defaultOpenVal) / defaultOpenVal) * 100;
      return parseFloat(change.toFixed(2)); // Directly format here
    });

    return [dates, opens];
  }, [trend]);

  // Debounced version of updateWidth
  const debouncedUpdateWidth = useMemo(() => debounce(updateWidth, 100), []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedUpdateWidth);
    if (chartRef.current && chartRef.current.parentElement) {
      resizeObserver.observe(chartRef.current.parentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [debouncedUpdateWidth]);

  useEffect(() => {
    if (chartRef.current && trend && trend.length > 0) {
      const opts = {
        width: width,
        height: 25,
        padding: [0, 0, -50, -50] as [number, number, number, number],
        series: [{}, { width: 1, stroke: "#325FFF" }],
        legend: { show: false },
        cursor: { show: false },
        axes: [{ grid: { show: false } }, { grid: { show: false } }],
      };

      const chart = new uPlot(opts, formattedData, chartRef.current);

      return () => chart.destroy();
    }
  }, [width, trend]); // Depend on width and trend

  return <div ref={chartRef} className="mx-auto max-w-[225px]"></div>;
};

const LoaderCell = (cellName: any) => <td data-cell={cellName}><LoaderCircle className="w-full animate-spin min-w-6 py-[2px]" /></td>

const ErrorCell = (cellName: any) => <td data-cell={cellName}><X className="w-full py-[2px]" /></td>

const BodyCell = ({ instrumentData, type, cellData }: { instrumentData: any; type: CellTypes, cellData: TableCell }) => {
  const [instrument, setInstrument] = useState<any>(null);
  type = type && type.toUpperCase() as CellTypes;

  useEffect(() => {

    if ( // Info endpoint
      type === "NAME" || 
      type === "MIN-SPREAD" ||
      type === "MIN-SPREAD-DISCOUNT" ||
      type === "MARGIN-RATE" ||
      type === "HOLDING-COST-BUY" ||
      type === "HOLDING-COST-SELL" ||
      type === "HOLDING-COST-BUY-AO" ||
      type === "HOLDING-COST-SELL-AO" ||
      type === "HOLDING-COST-BUY-OVERNIGHT" ||
      type === "HOLDING-COST-SELL-OVERNIGHT" ||
      type === "COMMISSION"
    ) {
      setInstrument(instrumentData?.info);
    }

    else if ( // Everything endpoint
      type === "TREND" || 
      type === "PRICE"
    ) {
      setInstrument(instrumentData?.everything);
    }

    else if ( // PriceChange endpoint
      type === "DAY" || 
      type === "WEEK" || 
      type === "MONTH" ||
      type === "YEAR"
    ) {
      setInstrument(instrumentData?.priceChange);
    }

    else if ( // Fixed values 
      type === "COMMISSION") {
      setInstrument("0");
    }
    
    else {
      type && console.error(`Invalid cell type: ${type}`);
    }
  }, [instrumentData, type]);

  // If no cell type, return the cell data directly from contentful
  if (!type) return <>{cellData}</>;

  if (!instrument) return <LoaderCell cellName={type} />;

  if (instrument === "Error") return <ErrorCell cellName={type} />;

  // ! All data manipulation should be done in the fetchData function
  switch (type) {
    case "MIN-SPREAD":
      if (!instrument.minSpread) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="MIN-SPREAD">
          {instrument.minSpread}
        </td>
      )

    // Use case: Forex active trader exotics table
    case "MIN-SPREAD-DISCOUNT":
      if (!instrument.minSpreadDiscount()) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="MIN-SPREAD-DISCOUNT">
          {instrument.minSpreadDiscount()}
        </td>
      )

    case "MARGIN-RATE":
      if (!instrument.marginRate) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="MARGIN-RATE">
          {instrument.marginRate}
        </td>
      )

    case "NAME":
      if (!instrument.name || !instrument.url) {
        return <LoaderCell cellName={type} />;
      }
      return (
        <td data-cell="NAME">
            <a href={`${instrument.url}`}>{instrument.name}</a>
        </td>
      )


    case "PRICE":
      if (!instrument.price) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="PRICE">
          {instrument.price}
        </td>
      )

    case "DAY":
      if (!instrument.day)  return <LoaderCell cellName={type} />;
      return (
        <td data-cell="DAY" className={cn(instrument.day?.startsWith("-") ? "price-down" : "price-up")}>
          {instrument.day}
        </td>
      )

    case "WEEK":
      if (!instrument.week) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="WEEK" className={cn(instrument.week?.startsWith("-") ? "price-down" : "price-up")}>
          {instrument.week}
        </td>
      )

    case "MONTH":
      if (!instrument.month) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="MONTH" className={cn(instrument.month?.startsWith("-") ? "price-down" : "price-up")}>
          {instrument.month}
        </td>
      )

    case "YEAR":
      if (!instrument.year) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="YEAR" className={cn(instrument.year?.startsWith("-") ? "price-down" : "price-up")}>
          {instrument.year}
        </td>
      )

    case "HOLDING-COST-BUY":
      if (!instrument.holdingCostBuy()) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="HOLDING-COST-BUY">
          {instrument.holdingCostBuy()}
        </td>
      );
    
    case "HOLDING-COST-SELL":
      if (!instrument.holdingCostSell()) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="HOLDING-COST-SELL">
            {instrument.holdingCostSell()}
        </td>
      );

    case "HOLDING-COST-BUY-AO":
      if (!instrument.holdingCostBuy()) return <LoaderCell cellName={type} />;
      return (
        <>
          <td data-cell="HOLDING-COST-BUY-OVERNIGHT">
            {instrument.holdingCostBuy()}
          </td>
          <td data-cell="HOLDING-COST-BUY-ANNUAL">
            {instrument.holdingCostBuyOvernight()}
          </td>
        </>
      )

    case "HOLDING-COST-SELL-AO":
      if (!instrument.holdingCostSell()) return <LoaderCell cellName={type} />;
      return (
        <>
          <td data-cell="HOLDING-COST-SELL-OVERNIGHT">
            {instrument.holdingCostSell()}
          </td>
          <td data-cell="HOLDING-COST-SELL-ANNUAL">
            {instrument.holdingCostSellOvernight()}
          </td>
        </>
      )

    case "HOLDING-COST-BUY-OVERNIGHT":
      if (!instrument.holdingCostBuyOvernight()) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="HOLDING-COST-BUY-OVERNIGHT">
          {instrument.holdingCostBuyOvernight()}
        </td>
      )

    case "HOLDING-COST-SELL-OVERNIGHT":
      if (!instrument.holdingCostSellOvernight()) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="HOLDING-COST-SELL-OVERNIGHT">
          {instrument.holdingCostSellOvernight()}
        </td>
      )

    case "TREND":
      if (!instrument.trend) return <LoaderCell cellName={type} />;
      return (
        <td data-cell="TREND">
          <Trend trend={instrument.trend} />
        </td>
      )

    case "COMMISSION":
      return (
        <td data-cell="COMMISSION">
         {instrument.commission()}
        </td>
      )
    
    default:
      return <ErrorCell cellName={type} />;
  }
  
};

const DynamicRow = ({ apiCode, heads, isMT4, rowData, uniqueRowId }: any) => {
  const isInView = useInView(uniqueRowId);

  // Reference to the row element
  const rowRef = useRef<HTMLTableRowElement>(null);

  // Locale determines intl formatting of minSpread column
  const router = useRouter();

  // Get locale and region to determine which instrument endpoints to call
  const { locale, region } = getRouteDetails(router);

// Append "-mt4" to the region if the table is for MT4
const regionAndType = 
  locale === 'zh' 
    ? 'zh' // Make exemption for ZH despite being alcatraz
    : isAlcatraz(locale)
      ? 'en' // Alcatraz is always "en"
      : `${region}${isMT4 ? '-mt4' : ''}`; // Rest is selective

  const [instrumentData, setInstrumentData] = useState<any>({
    info: null,
    everything: null,
    priceChange: null,
  });

  // Move this function somewhere better later
  const checkLocale = (locale: Locales) => {
    if (!locale) {
      return "en"
    }

    // Make an exclusion for ZH
    if (locale !== "zh" && isAlcatraz(locale as Locales)) {
      return "en";
    }

    return locale.toLowerCase();
  }

  // Manipulate and set the instrument data for each of the 3 endpoints
  // ! All data manipulation should be done here and not in the BodyCell component
  const fetchData = useCallback(async () => {
    try {
      const infoData = await WSFetcher.getInfo(apiCode, regionAndType as RegionAndType);
      const info = {
        // ? Name - The Instrument Name (e.g. Apple Inc, GBP/USD)
        name: infoData?.name ? infoData?.name?.replace(/- Cash$/, "") : null,

        // ? URL - The Instrument URL - e.g. / (locale) en|de-de|sv-se / (urlPath) instruments|instrumente / (url) apple-inc|tesla-inc
        url: infoData?.url
          ? `/${checkLocale(locale)}/${
              localeMap.get(locale)?.urlPath ?? "instruments"
            }/${infoData?.url}`
          : null,

        // ? Minimum spread - Formats the minimum spread value to a double decimal format based on the locale
        minSpread: infoData?.minimumSpread
          ? numberFormatter(locale).doubleDecimal.format(infoData?.minimumSpread)
          : null,

        // ? Used on FX Active exotics table - Calculates a 25% discount on the minimum spread
        minSpreadDiscount: infoData?.minimumSpread
          ? () => {
              return numberFormatter(locale).doubleDecimal.format(
                infoData?.minimumSpread - infoData?.minimumSpread * (25 / 100)
              );
            }
          : null,

        // ? Margin rate - Converts the margin rate to a percentage
        marginRate: infoData?.marginRate[0]?.rate
          ? `${
              infoData?.marginRate[0]?.rate
                ? Math.round(infoData.marginRate[0].rate * 100 * 1000) / 1000
                : 0
            }%`
          : null,

        // ? Holding cost buy - Converts the yearly holding cost buy percentage to a percentage string and formats it
        holdingCostBuy: () => {
          if (!infoData.lastHoldingRate?.yearlyPercentageBuy) return null;

          const percentage = numberFormatter(locale).doubleDecimal.format(
            infoData.lastHoldingRate.yearlyPercentageBuy * 100
          );
          const type = infoData.lastHoldingRate.yearlyPercentageBuyType.toLowerCase();
          const baseText = `${percentage}% (${type})`.replace("-", "");

          // Translate (charged) & (received) to the correct locale
          const chargedText = localeMap.get(locale)?.charged;
          const receivedText = localeMap.get(locale)?.received;
          return baseText
            .replace("charged", chargedText || "charged")
            .replace("received", receivedText || "received");
        },

        // ? Holding cost sell - Converts the yearly holding cost sell percentage to a percentage string and formats it
        holdingCostSell: () => {
          if (!infoData.lastHoldingRate?.yearlyPercentageSell) return null;

          const percentage = numberFormatter(locale).doubleDecimal.format(
            infoData.lastHoldingRate.yearlyPercentageSell * 100
          );
          const type = infoData.lastHoldingRate.yearlyPercentageSellType.toLowerCase();
          const baseText = `${percentage}% (${type})`.replace("-", "");

          // Translate (charged) & (received) to the correct locale
          const chargedText = localeMap.get(locale)?.charged;
          const receivedText = localeMap.get(locale)?.received;
          return baseText
            .replace("charged", chargedText || "charged")
            .replace("received", receivedText || "received");
        },

        // ? Holding cost buy overnight - Converts the yearly holding cost buy percentage to a daily percentage string and formats it
        holdingCostBuyOvernight: () => {
          if (!infoData.lastHoldingRate?.yearlyPercentageBuy) return null;

          const percentage = numberFormatter(locale).fourDecimal.format(
            (infoData.lastHoldingRate.yearlyPercentageBuy / 365) * 100
          );
          const type = infoData.lastHoldingRate.yearlyPercentageBuyType.toLowerCase();
          const baseText = `${percentage}% (${type})`.replace("-", "");

          // Translate (charged) & (received) to the correct locale
          const chargedText = localeMap.get(locale)?.charged;
          const receivedText = localeMap.get(locale)?.received;
          return baseText
            .replace("charged", chargedText || "charged")
            .replace("received", receivedText || "received");

          // infoData.lastHoldingRate?.yearlyPercentageBuy
          // ? `${((infoData.lastHoldingRate.yearlyPercentageBuy / 365) * 100)?.toFixed(4)}%(${infoData.lastHoldingRate.yearlyPercentageBuyType.toLowerCase()})`.replace("-", "")
          // : null,
        },

        // ? Holding cost sell overnight - Converts the yearly holding cost sell percentage to a daily percentage string and formats it
        holdingCostSellOvernight: () => {
          if (!infoData.lastHoldingRate?.yearlyPercentageBuy) return null;

          const percentage = numberFormatter(locale).fourDecimal.format(
            (infoData.lastHoldingRate.yearlyPercentageBuy / 365) * 100
          );
          const type = infoData.lastHoldingRate.yearlyPercentageBuyType.toLowerCase();
          const baseText = `${percentage}% (${type})`.replace("-", "");

          // Translate (charged) & (received) to the correct locale
          const chargedText = localeMap.get(locale)?.charged;
          const receivedText = localeMap.get(locale)?.received;
          return baseText
            .replace("charged", chargedText || "charged")
            .replace("received", receivedText || "received");

          // infoData.lastHoldingRate?.yearlyPercentageSell
          // ? `${((infoData.lastHoldingRate.yearlyPercentageSell / 365) * 100)?.toFixed(4)}%(${infoData.lastHoldingRate.yearlyPercentageSellType.toLowerCase()})`.replace("-", "")
          // : null,
        },

        commission: () => {
          const baseText = localeMap.get(locale)?.currencySymbol
            ? localeMap.get(locale)?.currencySymbol
            : "£";
          return `${baseText}0`;
        },
      };

      setInstrumentData((prev: any) => ({ ...prev, info }));
    } catch (err) {
      console.log(`Error fetching info data for: ${apiCode}`, err);
      setInstrumentData((prev: any) => ({ ...prev, info: "Error" }));
    }

    try {
      const everythingData = await WSFetcher.getEverything(apiCode);
      const everything = {
        // ? Buy price
        buy: everythingData?.price?.buy ? everythingData?.price?.buy : null,

        // ? Sell price
        sell: everythingData?.price?.sell
          ? everythingData?.price?.sell?.toFixed(2)
          : null,

        // ? Average price
        price:
          everythingData?.price?.buy && everythingData?.price?.sell
            ? numberFormatter(locale).doubleDecimal.format(
                (parseFloat(everythingData?.price?.buy) +
                  parseFloat(everythingData?.price?.sell)) /
                  2
              )
            : null,

        // ? Trend Chart
        trend: everythingData?.prices ? everythingData?.prices : null,
      };
      setInstrumentData((prev: any) => ({ ...prev, everything }));
    } catch (err) {
      console.log(`Error fetching everything data for: ${apiCode}`, err);
      setInstrumentData((prev: any) => ({ ...prev, everything: "Error" }));
    }

    try {
      const priceChangeData = await WSFetcher.getPriceChange(apiCode);
      const priceChange = {
        // ? Day - Formats the 1-day price movement to a percentage string with two decimal places
        day:
          priceChangeData?.["1day"]?.movement !== null &&
          priceChangeData?.["1day"]?.movement !== undefined
            ? `${numberFormatter(locale).doubleDecimal.format(
                priceChangeData["1day"].movement.toFixed(2)
              )}%`
            : null,

        // ? Week - Formats the weekly price movement to a percentage string with two decimal places
        week:
          priceChangeData?.["week"]?.movement !== null &&
          priceChangeData?.["week"]?.movement !== undefined
            ? `${numberFormatter(locale).doubleDecimal.format(
                priceChangeData["week"].movement?.toFixed(2)
              )}%`
            : null,

        // ? Month - Formats the 1-month price movement to a percentage string with two decimal places
        month:
          priceChangeData?.["1month"]?.movement !== null &&
          priceChangeData?.["1month"]?.movement !== undefined
            ? `${numberFormatter(locale).doubleDecimal.format(
                priceChangeData["1month"].movement?.toFixed(2)
              )}%`
            : null,

        // ? Year - Formats the yearly price movement to a percentage string with two decimal places
        year:
          priceChangeData?.["year"]?.movement !== null &&
          priceChangeData?.["year"]?.movement !== undefined
            ? `${numberFormatter(locale).doubleDecimal.format(
                priceChangeData["year"].movement?.toFixed(2)
              )}%`
            : null,
      };
      setInstrumentData((prev: any) => ({ ...prev, priceChange }));
    } catch (err) {
      console.log(`Error fetching priceChange data for: ${apiCode}`, err);
      setInstrumentData((prev: any) => ({ ...prev, priceChange: "Error" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode]);

  useEffect(() => {
    if (apiCode && isInView) fetchData();
  }, [isInView, fetchData, apiCode]);

  // If table is within a tab, trigger an update to the tab height when the row is updated
  useEffect(() => {
    if (rowRef && rowRef.current) {
      const parentTabsId = findParentComponentIdByType(rowRef.current, "nTabs");
      if (parentTabsId) {
        triggerUpdateTabHeight(parentTabsId);
      }
    }
  }, [instrumentData]);

  return (
    <tr ref={rowRef} id={uniqueRowId}>
      {heads.map((th: any, index: any) => {
        const header = th.props.children[0].props.children[0];

        const keywordMatch = header.match(/\{\{\{\s*(.*?)\s*\}\}\}/);
        const keyword = keywordMatch ? keywordMatch[1] : null;
        const key = `${apiCode}-${index}`;

        return (
          <BodyCell
            key={key}
            cellData={rowData[index]}
            instrumentData={instrumentData}
            type={keyword}
          />
        );
      })}
    </tr>
  );
};

/**
 * If the first column of the row contains an API code, fetches the data and renders the row dynamically.
 *
 * Otherwise it renders a static row.
 */
const BodyRow = ({ heads, data, index, uniqueTableId, localeMap }: any) => {
  try {
    const rowData = data.props.children;

    // Check if the first column contains an API code
    const firstColContent = rowData[0].props.children[0].props.children[0]
    const firstColText = typeof firstColContent === "string" ? firstColContent : "";
    const apiCodeMatch =  firstColText.match(/\{\{\{\s*([XN]-[A-Z]{5})\s*\}\}\}/);
    const apiCode = apiCodeMatch ? apiCodeMatch[1] : null;

    // Check if the table row is for MT4
    const mt4Match = firstColText.match(/\{\{\{\s*MT4\s*\}\}\}/);
    const isMT4 =  mt4Match ? true : false;

    if (apiCode) {

      return (
        <DynamicRow
          key={index + apiCode}
          heads={heads}
          apiCode={apiCode}
          rowData={rowData}
          isMT4={isMT4}
          uniqueRowId={`${uniqueTableId}-${apiCode}`}
        />
      );
    } else {
      return (
        <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
          {rowData.map((cell: any) => cell)}
        </tr>
      );
    }
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default BodyRow;